@mixin med4all-payment-overview-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .payment-overview {
    @extend .card;
    padding: map_get($spacers, 4);

    @include media-breakpoint-down(sm) {
      padding: map_get($spacers, 2);
    }

      .declaration-cb {
      .mat-checkbox-layout {
        white-space: normal;
      }
    }

    .mat-form-field-infix {
      border-top: 0;

      input {
        text-align: right;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          display: none;
        }
      }
    }

    .button-bar {
      margin-top: map_get($spacers, 5);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: flex-end;

        button:first-child {
          margin-bottom: 5px;
        }
      }

      button {
        min-width: 96px;
      }
    }
  }
}
