@use '@angular/material' as mat;

@mixin med4all-order-actions-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .home-actions-component {
    line-height: 21px;

    .overview-action {
      margin-bottom: map_get($spacers, 4);
      font-size: $font-size-md;

      a {
        color: mat.get-color-from-palette($palette, text-link);
      }
    }
  }
}
