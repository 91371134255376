@mixin med4all-document-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .document-dialog {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .select-file-btn {
      margin-bottom: 20px;
    }

    .button-bar {
      margin-top: map_get($spacers, 2);
      justify-content: center;
      width: 100%;
    }
  }
}
