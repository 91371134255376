@mixin med4all-medicines-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .medicines-page {
    @extend .container;

    .mat-tab-body-content {
      overflow: hidden;
    }
  }
}
