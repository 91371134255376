@use '@angular/material' as mat;

@mixin med4all-admin-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .admin-content {
    padding-top: 20px;
    margin-top: $header-height;
    height: calc(var(--vh, 1vh) * 100 - #{$header-height});
    overflow-y: auto;
    background: mat.get-color-from-palette($greys, light);

    @include media-breakpoint-down(sm) {
      margin-top: $header-height-md;
      height: calc(var(--vh, 1vh) * 100 - #{$header-height-md});
    }

    @include media-breakpoint-down(xs) {
      margin-top: $header-height-sm;
      height: calc(var(--vh, 1vh) * 100 - #{$header-height-sm});
    }

    .page-header {
      display: flex;
      align-items: center;
      padding: 0 15px;
      margin-bottom: $spacer;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: stretch;
      }

      .breadcrumbs {
        flex: 1;

        @include media-breakpoint-down(sm) {
          margin-bottom: $spacer;
        }
      }
    }
  }
}
