@mixin med4all-users-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .user-dialog-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .form-container {
      flex: 1;
      overflow-y: auto;
      padding-right: 10px;

      .user-form {
        display: flex;
        flex-direction: column;
      }

      .mat-form-field {
        margin-bottom: 20px;
      }
    }

    .button-bar {
      text-align: center;
      width: 100%;
      margin: $spacer 0;
    }
  }
}
