@use '@angular/material' as mat;

@mixin med4all-role-details-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .role-details-page {
    @extend .container;

    mat-form-field {
      display: block;
    }

    .permissions-container {
      margin-top: map_get($spacers, 3);

      .permission-name {
        color: mat.get-color-from-palette($greys, polaire);
        text-transform: capitalize;
        font-size: $font-size-lg;
      }
      .permission-description {
        color: mat.get-color-from-palette($greys, accessible);
        font-size: $font-size-md;
      }
    }
  }
}
