@use '@angular/material' as mat;

@mixin med4all-mcf-overview-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .mcf-panel {
    @extend .card-p0;

    .panel-header {
      padding: map_get($spacers, 2) map_get($spacers, 4);
      background-color: mat.get-color-from-palette($primary, 500);
      color: #fff;
    }

    .content {
      padding: map_get($spacers, 4);
      &.show {
        display: block !important;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      min-height: 0;

      .panel-header {
        padding: map_get($spacers, 1) map_get($spacers, 2);
      }

      .content {
        padding: map_get($spacers, 2);
      }
    }
  }
}
