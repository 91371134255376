@mixin med4all-orders-tab-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .orders-tab {
    .item-text {
      text-align: start !important;
    }
  }
}
