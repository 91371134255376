@mixin med4all-video-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .video-modal {
    background: #191919;
    padding: 54px;
    height: 100%;

    .dialog-header {
      display: flex;
      margin-bottom: 35px;

      .video-title {
        flex: 1;
        color: #ffffff;
        font-weight: bold;
        font-size: 27px;
      }

      .button {
        color: #ffffff;
      }
    }

    .video-wrapper {
      position: relative;
      float: none;
      clear: both;
      width: 100%;
      padding-bottom: 56.25%;
      padding-top: 25px;
      height: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
