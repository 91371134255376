@mixin med4all-inventory-take-tab-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .inventories__arrow-column {
    width: 3rem !important;
  }

  .info-row__header-column {
    font-weight: 500;
  }
}
