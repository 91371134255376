@mixin med4all-missing-medicines-table-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .missing-medicines-table {
    .mat-toolbar {
      background: #fff;
    }

    .mat-table {
      margin-bottom: 2px;

      .mat-cell {
        max-width: 20rem;
      }
    }
  }
}
