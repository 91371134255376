@use '@angular/material' as mat;

@mixin med4all-product-catalog-overview-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .spinner {
    position: fixed;
    z-index: 999;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin-top: 40vh;
  }

  .product-catalog-overview {
    @extend .container;
  }

  .table-wrapper {
    width: 100%;
    overflow-x: scroll;

    .table-column {
      width: fit-content;
    }
  }

  .circle {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;

    &--negative {
      background-color: mat.get-color-from-palette($palette, negative-red);
    }

    &--positive {
      background-color: mat.get-color-from-palette($palette, positive-green);
    }
  }

  .space-between {
    justify-content: space-between;
  }
}
