@use '@angular/material' as mat;

@mixin med4all-admin-documents-filter-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .admin-documents-filter-container {
    display: flex;
    margin-bottom: $spacer;

    [class*="col-"] {
      padding: $spacer;
      background-color: mat.get-color-from-palette($greys, perle);
    }

    .search-input-wrapper {
      display: block;
      height: 72px;

      .ng-fa-icon {
        color: mat.get-color-from-palette($palette, text-link);
        position: absolute;
        right: 28px;
        top: 25px;
        font-size: $font-size-lg;
      }

      .search-input {
        width: 100%;
        height: 100%;
        padding: 14px 12px;
        font-size: $font-size-lg;
        color: mat.get-color-from-palette($greys, accessible);
        border: none;
        border-bottom: 1px solid mat.get-color-from-palette($greys, accessible);
      }
    }

    .sort-wrapper {
      display: flex;
      align-items: center;
      padding: map_get($spacers, 3) map_get($spacers, 2) map_get($spacers, 3) map_get($spacers, 3);

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: start;

        .filter-label {
          margin-right: 0;
        }
      }

      .filter-label {
        margin-right: $spacer;
      }

      .filter-select {
        max-width: 200px;
        height: 40px;

        .mat-select-trigger {
          height: 100%;
          align-items: center;
          background-color: #fff;
          border-radius: 20px;
          border: 1px solid mat.get-color-from-palette($palette, text-link);
          padding: map_get($spacers, 2) 10px map_get($spacers, 2) 30px;
        }
      }
    }

    .filter-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: map_get($spacers, 3) map_get($spacers, 3) map_get($spacers, 3) map_get($spacers, 2);

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        align-items: start;

        .filter-label {
          margin-right: 0;
        }
      }

      .filter-label {
        margin-right: map_get($spacers, 3);
      }

      .filter-item {
        max-width: 140px;
      }

      .filter-select-btn {
        align-items: center;
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid mat.get-color-from-palette($palette, text-link);
        height: 40px;
        color: mat.get-color-from-palette($palette, text);
        font-size: $font-size-md;
        font-weight: normal;
        max-width: 150px;
        width: 100%;

        &__label {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 0;
          display: table-cell;
        }

        &__arrow {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid;
          margin: 0 4px;
          color: rgba(0, 0, 0, 0.54);
          display: table-cell;
        }
      }
    }

    .selected-filters {
      .selected-filter {
        height: 18px;
      }
    }
  }

  .chip-menu {
    .group-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      line-height: 48px;
      height: 48px;
      padding: 0 map_get($spacers, 3);
      font-weight: 500;
    }

    .mat-menu-item {
      padding: 0 32px;
    }
  }
}
