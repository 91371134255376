@mixin med4all-confirmation-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .confirmation-dialog-container {
    display: flex;
    flex-direction: column;
    max-width: 60vw;

    .question-container {
      margin-bottom: map_get($spacers, 3);
      line-height: 24px;
      max-height: 390px;
      overflow-y: auto;

      ul {
        list-style-type: disc;
        margin-inline-start: 30px;
        margin-left: 30px;
      }
    }

    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
    }

    th {
      border: 1px solid #000000;
      padding: 8px;
      text-align: left;
      word-wrap: break-word;
    }

    td {
      border: 1px solid #000000;
      padding: 8px;
      text-align: left;
      word-wrap: break-word;
    }


  }

  .confirmation-dialog__button-container {
    display: flex;
    align-items: center;
  }

  .confirmation-dialog__button-container--right {
    margin-left: auto;
  }
}
