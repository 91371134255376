@use '@angular/material' as mat;

@mixin med4all-messages-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .messages-component {
    padding: 24px;

    .message-list {
      .message-item {
        margin-bottom: 24px;

        .message-title {
          font-weight: 500;
          margin-bottom: 5px;
          font-size: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .message-content {
          font-size: $font-size-lg;
        }

        .message-date {
          color: mat.get-color-from-palette($greys, accessible);
        }
      }
    }
  }
}
