@mixin med4all-reject-order-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .reject-order-dialog-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .question-container {
      margin-bottom: 16px;
    }
  }
}
