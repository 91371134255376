@import '../../../../../node_modules/@angular/material/theming';

@mixin commission-monitor-calculate-commission-dialog-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .calculate-commission-dialog {
    min-width: 40rem;
    max-height: 60vh;
    padding-right: 10px;

    overflow-y: auto;
    overflow-x: hidden;
  }

  .calculate-commission-dialog__content-item {
    margin: 1.5rem 0 !important;
  }
}
