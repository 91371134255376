@mixin med4all-organizations-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .organization-dialog-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .form-container {
      flex: 1;
      overflow-y: auto;
      margin-bottom: map_get($spacers, 2);

      .organization-form {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .mat-form-field {
        margin-bottom: 20px;
      }
    }

    .button-bar {
      margin-top: map_get($spacers, 2);
      justify-content: center;
      width: 100%;
    }
  }
}
