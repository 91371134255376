@import '../../../../../../node_modules/@angular/material/theming';

@mixin med4all-keywords-creator-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .keywords-creator {
    .mat-form-field {
      margin-top: 10px;
    }
  }
}
