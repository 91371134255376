@mixin med4all-admin-orders-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .admin-orders-page {
    @extend .container;

    .info-row {
      box-shadow: inset 0 -1px 3px 0 rgba(0, 0, 0, 0.1);
    }
  }
}
