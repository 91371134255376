@mixin med4all-roles-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .roles-page {
    @extend .container;

    .item-actions {
      margin-bottom: 0 !important;

      button {
        width: 40px !important;
        margin-top: 0 !important;
      }
    }
  }
}
