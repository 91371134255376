@use '@angular/material' as mat;

* {
  box-sizing: border-box;

  a:hover,
  a:active,
  a:focus {
    text-decoration: none;
  }

  &:active,
  :focus {
    outline: none !important;
  }
}

label {
  margin: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: Interstate, "Helvetica Neue", sans-serif;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body > div {
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
}

@mixin paf-styles-reset($theme, $palette) {
  $primary: map_get($theme, primary);
  $accent: map_get($theme, accent);
  $foreground: map_get($theme, foreground);

  a {
    color: mat.get-color-from-palette($primary);
    cursor: pointer;

    &:hover {
      color: map_get($palette, primary);
    }
  }

  code {
    color: mat.get-color-from-palette($primary);
    background-color: mat.get-color-from-palette(mat.$grey-palette, 300);
  }

  body {
    color: map_get($palette, text);
  }
}
