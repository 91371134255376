@use '@angular/material' as mat;

@mixin med4all-order-journey-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .order-journey-content {
    margin-top: $header-height;
    height: calc(var(--vh, 1vh) * 100 - #{$header-height});
    background-color: mat.get-color-from-palette($greys, light);
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(sm) {
      margin-top: $header-height-md;
      height: calc(var(--vh, 1vh) * 100 - #{$header-height-md});
    }

    @include media-breakpoint-down(xs) {
      margin-top: $header-height-sm;
      height: calc(var(--vh, 1vh) * 100 - #{$header-height-sm});
    }

    .content {
      padding-top: 20px;
      overflow-y: auto;
      flex: 1;

      .page-header {
        display: flex;
        align-items: center;
        padding: 0 15px;
        margin-bottom: $spacer;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
          align-items: stretch;
          margin-bottom: $spacer;
        }

        .breadcrumbs {
          flex: 1;

          @include media-breakpoint-down(sm) {
            margin-bottom: $spacer;
          }
        }
      }
    }
  }
}
