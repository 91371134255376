@use '@angular/material' as mat;

@mixin med4all-alert-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .alert-container {
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 map_get($spacers, 3);

    &.warning {
      border: 1px solid mat.get-color-from-palette($palette, warning-yellow);
      background-color: #fff8eb;
    }

    .message {
      flex: 1;
      padding-left: map_get($spacers, 3);
      font-size: $font-size-sm;
      font-weight: 500;
    }

    .icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;

      &.warning {
        background-color: mat.get-color-from-palette($palette, warning-yellow);
        color: #fff;

        &:after {
          content: '!';
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
