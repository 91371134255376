@mixin med4all-payment-selection-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .payment-selection {
    @extend .card;
    padding: map_get($spacers, 4);
    min-height: 0;

    @include media-breakpoint-down(sm) {
      padding: map_get($spacers, 2);
    }
  }

  .payment-selection__table {
    @include media-breakpoint-down(md) {
      font-size: 12px;
    }
  }
}
