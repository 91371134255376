@mixin med4all-medicines-header-row-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .medicine-header-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 1rem 1rem;

    * {
      margin-right: 1rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .atc-code {
    margin-left: auto;
  }

  .medicine-header-info-row {
    padding-bottom: 0 !important;
  }
}
