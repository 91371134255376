@import '../../../../../node_modules/@angular/material/theming';

@mixin med4all-order-journey-details-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .order-journey-details-page {
    @extend .container;

    .mat-tab-label {
      &:nth-child(3) {
        border-right: 1px solid rgba(0, 0, 0, 0.12);
      }
    }

    .mat-tab-body-content {
      overflow: hidden;
    }

    .order-date {
      font-weight: 500;
      font-size: $font-size-lg;
      text-transform: uppercase;
      margin-bottom: map_get($spacers, 3);
    }
  }
}
