@use '@angular/material' as mat;

@mixin med4all-emails-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .emails-page {
    @extend .container;

    .item-text {
      text-align: start !important;
    }

    .details-row {
      padding: 20px !important;
    }

    .sent {
      color: mat.get-color-from-palette($palette, positive-green);
    }

    .failed {
      color: mat.get-color-from-palette($palette, negative-green);
    }

    .badge,
    .positive-badge {
      padding: 2px 5px;
    }

    .mat-paginator-container {
      margin-top: 3px;
    }
  }

  .emails__subject {
    display: flex !important;
    justify-content: space-between;
  }
}
