@mixin med4all-top-medicines-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .top-medicines-component {
    table {
      th {
        text-align: start;
        text-align: left;
        padding: 5px;
      }

      td {
        padding: 5px;
      }
    }
  }
}
