@mixin med4all-admin-toggle-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .admin-toggle {
    .toggle-label {
      color: #fff;
      padding-right: map_get($spacers, 3);
    }

    font-size: $font-size-sm;
    height: 30px;
    margin-bottom: 10px;

    .mat-button-toggle-label-content {
      line-height: 30px !important;
    }
  }
}
