@mixin med4all-update-password-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .update-password-page {
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(/assets/images/header-home.webp);
    display: flex;
    justify-content: center;
    align-items: baseline;

    @media (min-width: 576px) {
      background-image: url(/assets/images/header-home@2x.webp);
    }

    .page-container {
      margin-top: 30px;
      background: #ffffff;
      padding: 40px 54px;
      width: 564px;
      border-radius: 20px;
      box-shadow: 0 53px 80px 0 rgba(0, 0, 0, 0.68);
      min-height: 365px;

      h2 {
        font-weight: bold;
        font-size: 40px;
        margin-top: 0;
      }

      .warning {
        color: #D71621;
        margin-top: -12px;
        text-align: left;
        font-size: $font-size-sm;
      }

      .item {
        padding: 0;
        margin-bottom: 20px;

        .login-label {
          font-size: 1.6rem;
          color: #000;
        }
      }

      .mat-button {
        width: 180px;
      }
    }
  }
}
