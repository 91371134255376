@mixin med4all-delay-dialog-container-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .delay-dialog-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .message-box {
      height: 100px;
    }
  }
}
