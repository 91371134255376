@mixin med4all-cart-buttons-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .cart-buttons-container {
    button {
      margin: 0 $spacer $spacer 0;
      display: inline-block;
    }
  }
}
