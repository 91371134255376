@mixin med4all-admin-documents-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .admin-documents-page {
    @extend .container;

    .item-actions {
      .mat-icon-button {
        justify-content: center;
      }
    }

    .mat-paginator-container {
      margin-top: 3px;
    }
  }

  .admin-documents-page__name {
    word-wrap: break-word;
  }
}
