@mixin med4all-maintenance-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .maintenance-page {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 0 10%;
  }

  .maintenance-page__title {
    font-size: 48px;
  }

  .maintenance-page__subtitle {
  }
}
