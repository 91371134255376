@use '@angular/material' as mat;
@import 'node_modules/@angular/material/theming';

@mixin med4all-base-filter-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .base-filter {
    .filter-container {
      display: flex;
      margin-bottom: $spacer;
      align-items: center;
      background-color: mat.get-color-from-palette($greys, perle);
      padding: $spacer;

      .filter-container-divided {
        width: 100%;
      }

      .filter-container-right {
        display: flex;
        flex-wrap: wrap;
      }

      .search-input-wrapper {
        display: block;
        height: 40px;

        .ng-fa-icon {
          color: mat.get-color-from-palette($palette, text-link);
          position: absolute;
          right: 28px;
          top: 12px;
          font-size: $font-size-lg;
        }

        .search-input {
          width: 100%;
          height: 100%;
          padding: 14px 12px;
          font-size: $font-size-lg;
          color: mat.get-color-from-palette($greys, accessible);
          border: none;
          border-bottom: 1px solid mat.get-color-from-palette($greys, accessible);
        }
      }

      .filter-label {
        margin-right: map_get($spacers, 3);
      }

      .filter-select-btn {
        align-items: center;
        background-color: #fff;
        border-radius: 20px;
        border: 1px solid mat.get-color-from-palette($palette, text-link);
        height: 40px;
        color: mat.get-color-from-palette($palette, text);
        font-size: $font-size-md;
        font-weight: normal;
        max-width: 150px;
        width: 100%;
        margin: 10px;

        &__label {
          padding-right: map_get($spacers, 2);
        }

        &__arrow {
          width: 0;
          height: 0;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 5px solid;
          margin: 0 4px;
          color: rgba(0, 0, 0, 0.54);
          display: table-cell;
        }
      }

      .date-picker-container {
        max-width: 150px;
        width: 100%;
        position: relative;
        margin-right: 20px;

        input {
          position: absolute;
          top: 35px;
          visibility: hidden !important;
          width: 0 !important;
        }
      }

      .selected-filters {
        .selected-filter {
          height: 18px;
        }
      }
    }

    .chip-menu {
      .group-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        line-height: 48px;
        height: 48px;
        padding: 0 map_get($spacers, 3);
        font-weight: 500;
      }

      .mat-menu-item {
        padding: 0 32px;
      }
    }

    .active-filters-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      width: 100%;
    }
  }

  .filter-select {
    max-width: 200px;
    height: 40px;

    .mat-select-trigger {
      height: 100%;
      align-items: center;
      background-color: #fff;
      border-radius: 20px;
      border: 1px solid mat.get-color-from-palette($palette, text-link);
      padding: map_get($spacers, 2) 10px map_get($spacers, 2) 30px;
    }
  }
}
