@mixin med4all-loans-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .loans-page {
    @extend .container;

    .financial-details {
      @include media-breakpoint-down(md) {
        margin-top: 1rem;
      }
    }
  }
}
