@mixin med4all-order-message-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .order-message-dialog-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .message-container {
      margin-bottom: map_get($spacers, 3);
      overflow : auto;
      height: auto;
      max-height:500px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
    }

    th {
      border: 1px solid #000000;
      padding: 8px;
      text-align: left;
      word-wrap: break-word;
    }

    td {
      border: 1px solid #000000;
      padding: 8px;
      text-align: left;
      word-wrap: break-word;
    }

  }
}

