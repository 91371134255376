@mixin med4all-not-found-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .not-found-page {
    @extend .container;
    padding-top: 20px;
    margin-top: 100px;
  }
}
