@use '@angular/material' as mat;

@mixin med4all-cart-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .cart-button {
    display: flex;
    align-items: center;
    padding: 10px;
    background: #fff;
    color: mat.get-color-from-palette($palette, text-link);
    font-weight: 500;
    font-size: $font-size-md;
    justify-content: center;

    .icon-wrapper {
      position: relative;
      margin: 0 8px 0 8px;

      .number-of-items {
        background-color: mat.get-color-from-palette($palette, negative-red);
        color: #fff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -5px;
        right: -5px;
        width: 16px;
        height: 16px;
        font-size: $font-size-sm;
      }

      .ng-fa-icon {
        color: mat.get-color-from-palette($palette, text-link);
        font-size: 22px;
      }
    }
    .cart-label {
      text-transform: uppercase;
      color: mat.get-color-from-palette($palette, text-link);
    }
  }
}
