@mixin med4all-recover-email-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .recover-email-dialog-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px;

    .form-container {
      flex: 1;
      overflow-y: auto;
      padding-right: 10px;

      .recover-form {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .mat-form-field {
        margin-bottom: 20px;
      }
    }
  }
}
