@mixin med4all-side-menu-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .side-menu {

  }

  .mat-list-item:hover {
    cursor: pointer
  }
}
