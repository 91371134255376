@use '@angular/material' as mat;

@mixin med4all-users-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .users-page {
    @extend .container;

    .item-actions {
      margin-bottom: 0 !important;

      button {
        width: 40px !important;
        margin-top: 0 !important;
      }
    }

    .user-name {
      color: mat.get-color-from-palette($palette, text);

      &.deleted {
        color: mat.get-color-from-palette($greys, accessible);
      }
    }
  }
}


