@use '@angular/material' as mat;

@mixin med4all-stock-take-row-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .stock-take-row {
    margin-bottom: 1rem !important;
  }

  @include thumb_container($palette);

  .stock-take-tab__badge {
    width: max-content;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    justify-content: center;
    font-size: 15px;

    fa-icon {
      color: mat.get-color-from-palette($greys, accessible);
      font-size: 12px;
      padding: 0 10px;
    }
  }

  .stock-take-tab__badge-negative {
    align-items: center;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 500;
  }

  .stock-take-tab__button {
    font-size: 15px;
  }

  .info-row__checkbox-column {
    text-align: right;
  }

  .info-row__column-checkbox {
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: center !important;

    .approved-badge {
      margin-bottom: 0 !important;
    }
  }

  mat-checkbox .mat-checkbox-inner-container .mat-checkbox-ripple {
    position: fixed;
    left: inherit;
    top: inherit;
    transform: translate(-12px, -12px);
  }

  .ellipsis-button {
    border: none;
    cursor: pointer;
    background-color: transparent;
  }

  .flex-column {
    display: flex !important;
    flex-direction: column !important;
  }
}
