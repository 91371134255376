@use '@angular/material' as mat;

@mixin med4all-cms-emails-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .cms-emails-page {
    @extend .container;
  }

  .cms-emails-page-overview {

  }

  .cms-emails-page__settings-row {
    margin-top: 2rem;
  }

  .cms-emails-page__recipient-container-title {
    margin-bottom: 0 !important;
  }
  .cms-emails-page__recipient-container {
    display: flex;
    margin-bottom: 1rem;
  }

  .cms-emails-page__recipient {
    margin: 0 .5rem !important;

    b {
      font-weight: 500;
    }

    &:first-of-type {
      margin-left: 0 !important;
    }
  }

  .cms-emails-page__icon {
    margin-left: .5rem;
    cursor: pointer;
    color: mat.get-color-from-palette($greys, accessible);
  }

  .cms-emails-page__variables-panel {
    .mat-expansion-panel-header {
      background-color: mat.get-color-from-palette($greys, light) !important;

      &:hover {
        background-color: mat.get-color-from-palette($greys, light) !important;
      }
    }
  }

  .cms-emails-page__variables-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .cms-emails-page__variable {
    width: 45%;
    margin: 1rem 1rem;
    text-align: center;
  }

  .cms-emails__chip {
    padding: .5rem;
    background-color: mat.get-color-from-palette($greys, light);
    border-radius: .5rem;
  }

  .ql-toolbar {
    border-radius: 5px 5px 0 0;
  }
}
