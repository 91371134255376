@mixin med4all-forget-password-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .forget-password-dialog-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .form-container {
      flex: 1;
      overflow-y: auto;

      .mat-form-field {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }
}
