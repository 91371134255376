@mixin med4all-orders-home-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .orders-home-page {
    @extend .container;

    .cart-info {
      .orders-btn {
        margin-right: $spacer;
        margin-bottom: $spacer;
      }

      .orders-link {
        font-size: $font-size-lg;
        margin-bottom: $spacer;
      }

      @include media-breakpoint-down(sm) {
        .orders-btn {
          width: 100%;
        }

        .orders-link {
          white-space: nowrap;
          display: inline-block;
          text-align: center;
          width: 100%;
        }
      }
    }

    .row {
      margin-bottom: map_get($spacers, 3);

      > [class*=col-] {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
        [class*=col-] {
          margin-bottom: map_get($spacers, 3);
        }
      }

      .card-p4 {
        height: 100%;
      }

    }
  }
}
