@import '../../../../node_modules/@angular/material/theming';

@mixin med4all-missing-medicines-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .missing-medicines-page {
    @extend .container;

    [class*=mat-column-] {
      padding: 0 5px;
    }

    @include mat-table-columns((
      select: (width: 4rem),
      medicine: (width: 24rem, ellipsis: 'true'),
      supplier: (width: 16rem, ellipsis: 'true'),
      provider: (width: 16rem, ellipsis: 'true'),
      orderDate: (width: 6rem),
    ));
  }

  .status-cell {
    white-space: nowrap;
  }

  .info-icon {
    color: grey;
    font-size: 8px;
    vertical-align: text-bottom;
  }
}
