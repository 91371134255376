@mixin med4all-profile-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .profile-dialog-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;

    .form-container {
      flex: 1;
      overflow-y: auto;
      padding-right: 10px;

      .profile-form {
        display: flex;
        flex-direction: column;
      }

      .mat-form-field {
        margin-bottom: map_get($spacers, 2);
      }
    }

    .button-bar {
      text-align: center;
      width: 100%;
      padding-top: 20px;

    }

    .bordered-container {
      border: 1px solid rgba(0,0,0,.2);
      border-radius: $border-radius;
      padding: map_get($spacers, 3);
      margin: map_get($spacers, 3) 0;

      .password-match-error {
        @extend .mat-error;
        margin-top: map_get($spacers, 2);
      }
    }
  }
}
