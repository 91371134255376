@use '@angular/material' as mat;

@mixin med4all-home-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .home-page {
    overflow-y: auto;
    height: calc(100vh - 64px);

    .header-section {
      color: #fff;
      display: block;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      background-image: url(/assets/images/header-home.webp);

      @include media-breakpoint-up(sm) {
        background-image: url(/assets/images/header-home@2x.webp);
      }

      .text-container {
        display: inline-block;
        padding-top: 90px;

        @include media-breakpoint-down(sm) {
          padding-top: 50px;
        }

        h3 {
          font-size: 40px;
          font-weight: 700;
          position: relative;
          padding-bottom: 24px;
          line-height: 40px;

          &:after {
            content: '';
            width: 160px;
            height: 3px;
            background: #fff;
            position: absolute;
            bottom: -1px;
            left: 0;
          }
        }

        .content {
          line-height: 20px;
        }
      }

      .image-container {
        padding-top: 15px;
        position: relative;

        img {
          width: 500px;
          position: absolute;
          bottom: -100px;
        }
      }
    }

    .logos-section {
      @extend .container;
      display: flex;
      padding: 50px 0 50px 15px;
      align-items: center;

      .chag {
        max-width: 100px;
      }

      .pharmaccess {
        max-width: 150px;
        margin: 0 20px;
      }

      .nchs {
        max-width: 100px;
      }

      @include media-breakpoint-down(xs) {
        justify-content: center;
        padding: 30px 0 30px 15px;

        .chag {
          max-width: 50px;
        }

        .pharmaccess {
          max-width: 75px;
          margin: 0 20px;
        }

        .nchs {
          max-width: 50px;
        }
      }
    }

    .who-section {
      padding: 24px 0;
      background-color: #fff;

      .container {

        .text-container {
          display: inline-block;

          h3 {
            text-align: center;
            margin-bottom: 50px;
            font-size: 50px;
            position: relative;
            padding-bottom: 10px;
            line-height: 48px;

            &:after {
              content: '';
              width: 160px;
              height: 3px;
              background: mat.get-color-from-palette($palette, primary);
              position: absolute;
              bottom: -1px;
              left: 50%;
              margin-left: -80px;
            }
          }
        }

        .image-container {
          display: inline-block;

          img {
            width: 100%;
            border-radius: 16px;
          }
        }
      }
    }

    .features-section {
      margin-top: 100px;

      h3 {
        text-align: center;
        font-size: 50px;
        position: relative;
        padding-bottom: 20px;

        &:after {
          content: '';
          width: 160px;
          height: 3px;
          background: mat.get-color-from-palette($palette, primary);
          position: absolute;
          bottom: -1px;
          left: 50%;
          margin-left: -80px;
        }
      }

      img {
        width: 100%;
      }
    }

    .supply-section {
      background: mat.get-color-from-palette($palette, primary);
      margin-top: 100px;
      padding-top: 100px;
      overflow: hidden;
      height: 445px;

      @include media-breakpoint-down(md) {
        margin-top: 0;
        padding-top: 50px;
        padding-bottom: 50px;
        height: auto;
      }

      .text-container {
        display: inline-block;
        color: #fff;

        h3 {
          font-size: 50px;
          position: relative;
          padding-bottom: 20px;
          line-height: 55px;

          &:after {
            content: '';
            width: 160px;
            height: 3px;
            background: #ffffff;
            position: absolute;
            bottom: -1px;
            left: 50%;
            margin-left: -80px;
          }
        }

        .content {
          line-height: 20px;
          margin-top: 30px;
        }
      }

      .image-container {
        display: inline-block;

        img {
          width: 366px;
          height: 576px;
        }
      }
    }

    .faq-section {
      padding-top: 100px;
      padding-bottom: 100px;
      background: radial-gradient(circle, rgba(255, 255, 255, 1) 35%, rgba(239, 239, 239, 1) 100%);

      @include media-breakpoint-down(md) {
        padding-top: 50px;
        padding-bottom: 50px;
      }

      h3 {
        text-align: center;
        font-size: 50px;
        position: relative;
        padding-bottom: 20px;
        margin-bottom: 40px;
        line-height: 50px;



        &:after {
          content: '';
          width: 160px;
          height: 3px;
          background: mat.get-color-from-palette($palette, primary);
          position: absolute;
          bottom: -1px;
          left: 50%;
          margin-left: -80px;
        }
      }

      .faq-component {
        box-shadow: 0 23px 36px 0 rgba(0, 0, 0, 0.19);
        padding: 50px;
        background: #ffffff;

        @include media-breakpoint-down(sm) {
          padding: 30px;
        }

        .ion-item {
          background: #ffffff;
        }
      }

      .image-container {
        flex: 1;

        img {
          width: 100%;
        }
      }
    }

    .numbers-section {
      padding: 50px 0;
      background: mat.get-color-from-palette($palette, primary);
      background: radial-gradient(circle, rgba(80, 119, 255, 1) 35%, rgba(10, 58, 227, 1) 100%);

      h3 {
        text-align: center;
        font-size: 50px;
        font-weight: 700;
        position: relative;
        padding-bottom: 20px;
        color: #fff;
        line-height: 50px;
        margin-bottom: 40px;

        &:after {
          content: '';
          width: 160px;
          height: 3px;
          background: #fff;
          position: absolute;
          bottom: -1px;
          left: 50%;
          margin-left: -80px;
        }
      }

      .number-container {
        height: 158px;
        border: 1px solid #ffffff;
        border-radius: 5px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 30px;

        @include media-breakpoint-down(sm) {
          height: 85px;
        }

        &:not(:last-child) {
          margin-right: 5px;
        }

        .number {
          font-size: 40px;
          font-weight: bold;
        }

        .label {
          margin-top: 5px;
          font-size: 13px;
          text-transform: uppercase;
        }
      }
    }

    .footer {
      background: #000;
      color: #fff;

      .contact-container {
        padding: 10px;

        .contact-item {
          line-height: 20px;
          width: 100%;

          a {
            color: #fff;
          }
        }
      }
    }
  }
}
