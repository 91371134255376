@mixin mat-table-columns($columns) {
  .mat-column- {
    @each $colName, $props in $columns {
      &#{$colName} {
        @if map-has-key($props, 'width') {
          flex: none;
          max-width: map-get($props, 'width');
        }

        @if map-has-key($props, 'color') {
          color: map-get($props, 'color');
        }

        @if map-has-key($props, 'ellipsis') {
          overflow: hidden;
          width: auto;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
