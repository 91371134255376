@use '@angular/material' as mat;
$med4all-primary: (
  50 : #e0eef5,
  100 : #b3d6e5,
  200 : #80bad4,
  300 : #4d9ec3,
  400 : #268ab6,
  500 : #0075a9,
  600 : #006da2,
  700 : #006298,
  800 : #00588f,
  900 : #00457e,
  A100 : #acd3ff,
  A200 : #79b8ff,
  A400 : #469dff,
  A700 : #2c90ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$med4all-secondary: (
  50 : #e2eeff,
  100 : #b6d4fe,
  200 : #86b7fe,
  300 : #569afe,
  400 : #3184fd,
  500 : #0d6efd,
  600 : #0b66fd,
  700 : #095bfc,
  800 : #0751fc,
  900 : #0057d7,
  A100 : #ffffff,
  A200 : #eff2ff,
  A400 : #bcc8ff,
  A700 : #a2b4ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$med4all-warning: (
  50 : #f7e0e0,
  100 : #ecb3b3,
  200 : #e08080,
  300 : #d34d4d,
  400 : #c92626,
  500 : #c00000,
  600 : #ba0000,
  700 : #b20000,
  800 : #aa0000,
  900 : #9c0000,
  A100 : #ffc7c7,
  A200 : #ff9494,
  A400 : #ff6161,
  A700 : #ff4747,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$med4all-greys: (
  light: #f5f5f5,
  perle: #e8e5e5,
  accessible: #767676,
  ardoise: #413c48,
  polaire: #051039
);


$med4all-palette: (
  primary: mat.get-color-from-palette($med4all-primary, 500),
  accent: mat.get-color-from-palette($med4all-secondary, 500),
  white: #ffffff,
  border: #d6e5f3,
  text: mat.get-color-from-palette($med4all-greys, polaire),
  negative-red: #c00000,
  warning-yellow: #ffa800,
  positive-green: #72bb23,
  text-link: mat.get-color-from-palette($med4all-primary, 500),
  text-success: #22780f
);

$enable-grid-classes: true;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1263px
) !default;

@include _assert-ascending($container-max-widths, "$container-max-widths");

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1400px
) !default;

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
$grid-columns:                12 !default;
$grid-gutter-width:           30px !default;

$spacer: 1rem !default; //16px
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * .25), // 4px
  2: ($spacer * .5),  // 8px
  3: $spacer,         // 16px
  4: ($spacer * 1.5), // 24px
  5: ($spacer * 3)    // 48px
), $spacers);

$mat-elevation-color: #000;
$mat-elevation-opacity: 0.1;
$border-radius: 5px;
$padding: 16px;
$header-height: 140px;
$header-height-md: 100px;
$header-height-sm: 64px;
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 16px;
