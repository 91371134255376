// UTIL CLASSES
@use "sass:math";

.flex-row {
  display: flex !important;
  flex-direction: row;
}

.flex-col {
  display: flex !important;
  flex-direction: column;
}

.full-width {
  width: 100% !important;
  display: block;
}

.full-height {
  height: 100% !important;
  display: block;
}

.divider {
  border-left: 2px solid var(--ion-color-medium);
  margin: 10px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.elevation {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
}

.text-bold {
  font-weight: 500;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: end !important;
}

.text-md-end {
  text-align: end !important;

  @include media-breakpoint-down(md) {
    text-align: start !important;
  }
}

.text-start {
  text-align: start !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.no-wrap {
  white-space: nowrap;
}

.font-size-sm {
  font-size: $font-size-sm;
}

.font-size-md {
  font-size: $font-size-md;
}

.font-size-lg {
  font-size: $font-size-lg;
}

.clickable {
  cursor: pointer;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.hidden {
  visibility: hidden !important;
  width: 0 !important;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

//
// Utilities for common `display` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .d#{$infix}-none {
      display: none !important;
    }
    .d#{$infix}-inline {
      display: inline !important;
    }
    .d#{$infix}-inline-block {
      display: inline-block !important;
    }
    .d#{$infix}-block {
      display: block !important;
    }
    .d#{$infix}-table {
      display: table !important;
    }
    .d#{$infix}-table-row {
      display: table-row !important;
    }
    .d#{$infix}-table-cell {
      display: table-cell !important;
    }
    .d#{$infix}-flex {
      display: flex !important;
    }
    .d#{$infix}-inline-flex {
      display: inline-flex !important;
    }
  }
}

// colors in palette
@each $c, $val in $med4all-palette {
  .color-#{"" + $c} {
    color: $val !important;
  }
  .bgcolor-#{"" + $c} {
    background-color: $val !important;
  }
}

// Margin and Padding
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {

        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
        .#{$abbrev}h#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}v#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
          #{$prop}-bottom: $length !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto {
      margin: auto !important;
    }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}

//table column widths
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      @for $i from 1 through $grid-columns {
        .td#{$infix}-#{$i} {
          width: percentage(math.div($i, $grid-columns));
      }
    }
  }
}
