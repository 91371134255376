@use '@angular/material' as mat;

@mixin med4all-faq-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .faq-component {

    .faq-list {
      .question {
        font-size: 18px;
        font-weight: 500;
        color: mat.get-color-from-palette($palette, text-link);
      }

      .answer {
        color: #585858;
        font-size: $font-size-md;
      }
    }
  }
}
