@mixin med4all-uploaded-pod-list-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .previous-documents {
    display: flex;
    justify-content: flex-start;
    margin: 0;

    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  .previous-document {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 18%;
    margin-bottom: 1rem;
    margin-right: 1rem;
    text-align: center;
    overflow-wrap: anywhere;

    fa-icon {
      font-size: 50px;
    }

    p {
      margin-top: 1rem !important;
    }

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    @include media-breakpoint-down(md) {
      width: 45%;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-right: 0;
    }
  }
}
