@mixin med4all-cart-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .cart-page {
    @extend .container;

    .page-header {
      flex-direction: row !important;
    }
  }
}
