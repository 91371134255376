@use '@angular/material' as mat;
@import 'variables';

.item-table {
  .item-row {
    @extend .card;
    min-height: 72px;
    margin: 0 0 map_get($spacers, 2) 0;
    align-items: center;

    .col-12 {
      margin-bottom: 0;
    }

    .details-button {
      color: mat.get-color-from-palette($med4all-palette, text-link)
    }

    .item-label {
      .details-button {
        color: mat.get-color-from-palette($med4all-palette, text-link)
      }


      display: flex;
      justify-content: flex-start;
      align-items: center;

      .item-text__value {
        @extend .mat-body-2;
      }
    }

    .item-icon-with-text {
      display: flex;
      align-items: center;

      .item-icon {
        min-width: 32px;
        max-width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        &.status {
          background-color: mat.get-color-from-palette($med4all-palette, positive-green);
          border-radius: 50%;

          .ng-fa-icon {
            color: #fff;
            margin: 0;
          }
        }

        .ng-fa-icon {
          font-size: 20px;
          color: mat.get-color-from-palette($med4all-palette, text-link);
        }
      }

      .item-text.empty {
        color: mat.get-color-from-palette($med4all-greys, accessible);
        margin-left: 43px;

        @include media-breakpoint-down(xs) {
          margin-left: 0;

          .item-text__value {
            margin-left: 43px;
          }
        }
      }


    }

    .item-text {
      text-align: center;

      &__label {
        font-size: $font-size-sm;
        display: block;
        color: mat.get-color-from-palette($med4all-greys, accessible);

        &.warn {
          color: mat.get-color-from-palette($med4all-palette, negative-red);
        }
      }

      &__link {
        color: mat.get-color-from-palette($med4all-primary, 500);
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          text-decoration: none;

        }
      }

      &__value {
        font-size: $font-size-md;
        display: block;

        &.warn {
          color: mat.get-color-from-palette($med4all-palette, negative-red);
        }
      }

      @include media-breakpoint-down(xs) {
        text-align: start;
        margin-bottom: map_get($spacers, 2) !important;
        display: flex;
        align-items: center;

        &__label {
          min-width: 40px;
          margin-right: map_get($spacers, 2);
        }

      }
    }

    .item-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      .ng-fa-icon {
        margin-right: 5px;
        font-size: 20px;
        color: mat.get-color-from-palette($med4all-greys, accessible);
      }
    }

    .item-input {
      display: flex;
      align-items: center;

      .mat-form-field {
        .mat-form-field-infix {
          width: 100%;
        }
      }
    }

    .item-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include media-breakpoint-down(sm) {
        margin-bottom: $spacer;

        button {
          margin-top: map_get($spacers, 3);
          width: 100%;
        }
      }
    }

    .item-checkbox {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include media-breakpoint-down(sm) {
        position: absolute;
        top: 24px;
        width: auto;
        right: 0;
      }
    }

    .info-row {
      @extend .row;
      padding: $spacer 0;
    }

    .row-padding {
      padding: 1rem;
    }

    .info-row__details-padding {
      @include media-breakpoint-up(lg) {
        padding-left: 4rem;
      }
    }

    .info-row__flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .info-row__column__auto-width {
      width: auto !important;
    }

    .info-row__column {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      width: 15%;
      overflow: hidden;
      text-overflow: ellipsis;

      .info-row__column-part {
        display: flex;
        min-height: 2rem;
        margin-bottom: 1rem;
        flex-direction: column;
        justify-content: center;

        &:last-of-type {
          margin-bottom: 0;
          margin-right: 0;
        }

        @include media-breakpoint-down(md) {
          .item-text__label {
            margin-right: 0;
          }

          &:last-of-type {
            text-align: right;
          }
        }
      }

      @include media-breakpoint-between(lg, xl) {
        width: 10rem;
      }

      @include media-breakpoint-down(md) {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    .details-row {
      @extend .row;
      padding: 20px 0;
      position: relative;

      > [class*="col-"] {
        text-align: center;

        &:last-child {
          text-align: end;
        }

        &:first-child {
          text-align: start;
        }
      }

      .item-details--label {
        color: mat.get-color-from-palette($med4all-greys, accessible);
        font-size: $font-size-sm;
        margin-right: 5px;
      }

      @include media-breakpoint-down(sm) {
        box-shadow: inset 0 -1px 3px 0 rgba(0, 0, 0, 0.1), inset 0 1px 3px 0 rgba(0, 0, 0, 0.1);

        > [class*="col-"], > [class*="col-"]:last-child {
          text-align: start;
          margin-bottom: map_get($spacers, 2) !important;
        }
      }
    }
  }
}
