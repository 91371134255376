@use '@angular/material' as mat;

@mixin med4all-admin-pay-orders-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .admin-pay-orders-dialog__table {
    min-width: 40rem;
    margin-bottom: 2rem;

    max-height: 60vh;
    overflow-y: scroll;
    padding-right: 10px;
  }

  .admin-pay-orders-dialog__item-row {
    background-color: mat.get-color-from-palette($greys, light);
  }

  .admin-pay-orders-dialog__checkbox {
    justify-content: start !important;
  }
}
