@use '@angular/material' as mat;

@mixin med4all-upload-pod-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .upload-pod-page {
    @extend .container;

    .dropzone {
      width: 100%;
      padding: 2rem;
      text-align: center;
      border: dashed 1px #979797;
      border-radius: $border-radius;
      position: relative;
      margin: 0 1rem;

      fa-icon {
        font-size: 24px;
      }

      input {
        opacity: 0;
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
      }

      label {
        color: white;
        width: 183px;
        height: 44px;
        border-radius: $border-radius;
        background-color: mat.get-color-from-palette($med4all-theme-primary);
        padding: 8px 16px;
      }

      h3 {
        font-size: 20px;
        font-weight: 600;
        color: #38424c;
      }
    }

    .file-list {
      margin-top: 3rem;
      text-align: left;
      display: flex;
      flex-direction: column;

      * {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      fa-icon {
        margin-right: 1rem;
      }
    }

    .warning-bar {
      background-color: mat.get-color-from-palette($med4all-theme-warn, 400);
      color: whitesmoke;
    }
  }
}
