@use '@angular/material' as mat;

@mixin med4all-waybill-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .waybill-page {
    @extend .container;

    .mat-radio-label {
      flex-direction: column;
      padding: 0 1rem 1rem;
      width: auto;

      .mat-radio-label-content {
        font-size: $font-size-sm;
        color: mat.get-color-from-palette($med4all-greys, accessible);
        padding-right: 0;
        padding-bottom: 5px;
      }
    }

  }

  .waybill-page__contact-form {
  }

  .waybill-page__contact-form--fieldset {
    justify-content: center;
    border: none;
    margin-left: 0;
    min-height: auto;
  }

  .waybill-page__contact-form--semi-hidden-input {
    border: none;
    margin-left: 5px;
    background-color: transparent;
  }

  .waybill-page__contact-form--email {
    width: 80%;
  }

  .waybill-page__details-row {
    display: flex;
    flex-direction: column;
  }

  .waybill-button-row {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .waybill-action-row {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .waybill-quantity-form-field {
    padding-top: 0;
    width: 20rem;

    .mat-form-field-wrapper {
      margin-bottom: 1rem
    }
  }
}
