@mixin med4all-formulary-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .formulary-page {
    @extend .container;
    margin-bottom: 3rem;

    .toggle-btn__container {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      @include media-breakpoint-down(sm) {
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
      }

      .toggle-btn__label {
        margin-right: map_get($spacers, 2);
        font-size: $font-size-lg;
      }
    }

    .save-btn {
      margin-right: map_get($spacers, 3);

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    > .row {
      margin-bottom: map_get($spacers, 3);

      > [class*=col-] {
        margin-bottom: 0;
      }
      @include media-breakpoint-down(sm) {
        margin-bottom: 0;

        &.item-row {
          padding: map_get($spacers, 3) 0 0;
        }

        > [class*=col-] {
          margin-bottom: map_get($spacers, 3);
        }
      }
    }
  }
}
