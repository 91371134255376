@use '@angular/material' as mat;

@mixin med4all-auth-header-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .auth-header {
    position: fixed;
    width: 100%;
    max-height: $header-height;
    top: 0;
    z-index: 2;

    .mat-toolbar {
      background: mat.get-color-from-palette($greys, polaire);

      .header-bar {
        height: 100px;

        @media(max-width: #{map-get($grid-breakpoints, sm)}) {
          height: 64px;
        }

        .menu-btn {
          .ng-fa-icon {
            color: #fff;
          }
        }

        .logo {
          margin-left: 22px;
          display: flex;
          align-items: center;
          height: 100%;

          img {
            height: 44px;
          }
        }

        .user-popup-selector {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-right: 20px;
          cursor: pointer;
          color: #fff;

          .user-avatar {
            width: 40px;
            height: 40px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            color: mat.get-color-from-palette($palette, text);
          }

          .user-name-container {
            display: flex;
            flex-direction: column;
            margin: 0 20px;
            text-align: start;
            height: 40px;
            line-height: 18px;

            .user-name {
              font-size: 15px;
            }

            .user-organization {
              font-size: 11px;
            }

            .user-role {
              font-size: 11px;
            }
          }
        }
      }

      .menu-bar {
        height: 40px;
        justify-content: space-between;
        overflow: auto;
        color: white;

        .mat-button {
          min-width: 100px;
          text-transform: uppercase;
          padding-bottom: 4px;
          color: #fff;
          border-radius: 0;
          margin-right: -7px;

          &.active {
            border-bottom: 4px solid #fff;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
