@mixin med4all-loader-dialog-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .loader-container {
    @include align-items(center);
    @include flexbox();
    @include flex-direction(column);

    .loading-text {
      margin-top: 10px;
      text-align: center;
    }
  }
}



