@import '@angular/material/theming';
@import './unknown-medicines/unknown-medicines.page';
@import './unknown-medicines/components/medicine-creator/medicine-creator.component';
@import './unknown-medicines/components/brand-creator/brand-creator.component';
@import './unknown-medicines/components/keywords-creator/keywords-creator.component';
@import './emails/emails.page';
@import './product-catalog/overview/product-catalog-overview.component';

@mixin med4all-cms-module-theme($theme, $palette) {
  @include med4all-unknown-medicines-page-theme($theme, $palette);
  @include med4all-medicine-creator-component-theme($theme, $palette);
  @include med4all-brand-creator-component-theme($theme, $palette);
  @include med4all-keywords-creator-component-theme($theme, $palette);
  @include med4all-cms-emails-page-theme($theme, $palette);
  @include med4all-product-catalog-overview-theme($theme, $palette);
}

.cms-action-button {
  height: 37px;
}

.cms-keywords-thumbnail,
.cms-keywords-image {
  &:hover {
    cursor: pointer;
  }
}

.cms-keywords-image {
  max-width: 100%;
}

.brands-cms {
  .mat-form-field-appearance-fill .mat-form-field-flex {
    height: 3.5rem;
  }
}
