@use '@angular/material' as mat;
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@mixin med4all-global-theme($theme, $palette) {
  $greys: map_get($theme, greys);

  html, body {
    height: 100%;
  }
  body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
  }

  .spacer {
    flex: 1 1 auto;
  }

  a, a:hover, a:active, a:visited {
    color: mat.get-color-from-palette($palette, text-link);
  }

  fa-icon[icon="times"] {
    color: mat.get-color-from-palette($palette, text-link);
    font-size: 20px;
  }

  .dialog-header {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    h2 {
      flex: 1;
      margin-bottom: 0 !important;
    }
  }

  ul {
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    margin-inline-start: 0;
    padding-inline-start: 0;

    &.bullet-list {
      list-style-type: disc;
      padding-left: 20px;
      padding-inline-start: 20px;
    }
  }

  .icon-btn {
    .ng-fa-icon {
      margin-right: 5px;
    }
   }

  .page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .badge,
  .positive-badge,
  .info-badge,
  .accent-badge,
  .grey-badge {
    border-radius: $border-radius;
    padding: 2px 5px;
    color: #fff;
    margin-right: 10px;

    @include media-breakpoint-down(sm) {
      display: block;
    }
  }

  .badge {
    background-color: mat.get-color-from-palette($palette, negative-red);
  }

  .positive-badge {
    background-color: mat.get-color-from-palette($palette, positive-green);
  }

  .info-badge {
    background-color: mat.get-color-from-palette($palette, primary, 0.8);
  }


  .accent-badge {
    background-color: mat.get-color-from-palette($palette, accent, 0.8);
  }

  .grey-badge {
    background-color: mat.get-color-from-palette($greys, perle);
    color: mat.get-color-from-palette($palette, text);
  }
}
