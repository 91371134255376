.mat-dialog-container {
 overflow: hidden !important;
}

.user-modal {
  height: 535px !important;
  width: 500px !important;
}

.confirmation-dialog {
  max-width: 50vw !important;
  max-height: 535px !important;
  @include media-breakpoint-down(sm) {
    max-width: 80vw !important;
  }
}

.order-dialog {
  min-width: 350px !important;
  max-width: 50vw !important;
  @include media-breakpoint-down(sm) {
    max-width: 80vw !important;
  }
}

.organization-modal {
  height: 500px;
  width: 500px;
}

.profile-modal {
  height: 75vh !important;
  width: 500px !important;
}

.forget-password-dialog {
  min-width: 300px !important;
}

.document-dialog {
  min-width: 300px !important;
}

.recover-password-modal {
  height: 345px !important;
  width: 540px !important;
}

.recover-email-dialog {
  height: 650px !important;
  width: 540px !important;
}

.organization-selector-modal {
  height: 250px !important;
  width: 600px !important;
}

.organization-selector-multiple-modal {
  height: 500px !important;
  width: 600px !important;
}

.video-modal {
  width: 1050px !important;
  height: 690px !important;
}

.email-dialog {
  height: 570px !important;
  width: 540px !important;
}
