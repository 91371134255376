@use '@angular/material' as mat;

@mixin med4all-order-journey-table-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .order-journey-table {
    .toggle-container {
      .mat-slide-toggle-bar {
        margin-left: map_get($spacers, 3);
      }

      .mat-slide-toggle-content {
        text-transform: uppercase;
        font-weight: 500;

        span.active {
          color: mat.get-color-from-palette($palette, primary);
        }
      }
    }

    .status {
      &.OKAY {
        color: mat.get-color-from-palette($palette, text);
      }

      &.RISK {
        font-weight: 500;
        color: mat.get-color-from-palette($palette, warning-yellow);
      }

      &.OVERDUE {
        font-weight: 500;
        color: mat.get-color-from-palette($palette, negative-red);
      }
    }
  }

  .order-journey-table__button-row {
    display: flex;
    justify-content: flex-end;
  }
}
