@use '@angular/material' as mat;

@mixin med4all-delivery-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .delivery-page {
    @extend .container;

    .mat-radio-label {
      flex-direction: column;
      padding: 0 1rem 1rem;
      width: auto;

      .mat-radio-label-content {
        font-size: $font-size-sm;
        color: mat.get-color-from-palette($med4all-greys, accessible);
        padding-right: 0;
        padding-bottom: 5px;
      }
    }

    @include media-breakpoint-down(sm) {
      .mat-raised-button {
        white-space: normal;
        line-height: 24px;
        min-height: 36px;
      }
    }

    @include media-breakpoint-down(xs) {
      .item-text {
        display: block !important;
      }

      .delivery-page__button {
        width: 100%;
        margin-right: 0 !important;
        margin-left: 0 !important;
      }

    }

    .info-row {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
  }

  .action-row {
    width: 100%;
    justify-content: flex-start;
    padding-top: 0 !important;
  }

  .action-row {
    margin: 0 !important;
  }

  .delivery-row,
  .delivery-page__radio-group,
  .delivery-page__radio-button {
    margin: 0 !important;
    padding: 0 !important;
  }

  .details-row-delivery {
    padding: 0 1rem 1rem !important;
  }
}
