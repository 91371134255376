@use '@angular/material' as mat;

@mixin med4all-order-journey-comments-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .order-journey-comments-component {
    @extend .container;

    .comments-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .comments-header {
        display: flex;
        justify-content: space-between;
      }

      .comment {
        margin-bottom: map_get($spacers, 3);

        .comment-header {
          color: mat.get-color-from-palette($palette, text-link);
          margin-bottom: map_get($spacers, 2);
        }
      }

      .comments {
        flex-grow: 1;
      }

      * {
        margin-bottom: 1rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
