@mixin med4all-support-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .support-page {
    @extend .container;

    .breadcrumbs {
      margin-bottom: 35px;
    }

    [class*="col-"] {
      margin-bottom: $spacer;
    }
  }
}
