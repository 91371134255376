@mixin med4all-breadcrumb-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .breadcrumb-component {
    .breadcrumbs-container {
      display: flex;
      flex-direction: row;
      align-items: center;

      h1 {
        display: flex;
        margin: 0;
      }

      .crumb-divider {
        font: 500 24px/32px Roboto, sans-serif;
        margin: 0 10px;
      }
    }
  }

}
