@mixin med4all-unknown-medicines-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .unknown-medicines-page {
    @extend .container;

    @include thumb_container($palette);

    .source-container {
      width: 400px;

      img {
        width: 100%;
        display: block;
        position: sticky;
        top: 0;
      }
    }

    .creator-block {
      margin-bottom: 1rem;
      display: block;
    }
  }

  .filter-container-unknown-medicine {
    border-style: solid;
    border-width: thin;
    padding-left: 5px;
    padding-right: 5px;
   }
  
   .filteredCount{
    float: right;
  }
  
  .filtered-unknown-medicine { 
    .mat-form-field-flex{
      outline: none !important;
      border: 2px solid;
      border-image-slice: 1 !important;
      border-width: 3px;
      border-image-source: linear-gradient(to right, #0d6efd, #3d4095);
      animation: animate 2.5s linear infinite;
    }
    
  @keyframes animate {
    100%{
      filter: hue-rotate(-360deg);
    }
  }
   }
}
