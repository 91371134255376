@use '@angular/material' as mat;

@mixin med4all-payment-selection-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .payment-selection-page {
    @extend .container;

    .header {
      font-weight: 500;
      font-size: $font-size-lg;
    }

    table {
      width: 100%;
      table-layout: fixed;
      display: table;

      thead {
        th {
          text-align: left;
          font-weight: normal;
          font-size: $font-size-sm;
          color: mat.get-color-from-palette($palette, text-link);

          &:last-child {
            text-align: right;
          }
        }
      }

      tr {
        &.warn {
          color: mat.get-color-from-palette($palette, negative-red);
        }

        td {
          display: table-cell;
          padding: 5px 0;

          .mat-slide-toggle {
            display: inline-block;
          }

          .mcf-label {
            margin-left: $spacer;
          }


        }
      }
    }
  }

  .payment-method-name {
    vertical-align: text-top;
  }
}
