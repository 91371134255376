@mixin med4all-admin-home-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .admin-home-page {
    @extend .container;

    .row {
      margin-bottom: map_get($spacers, 3);

      > [class*=col-] {
        margin-bottom: 0;

        > [class*=card-] {
          height: 100%;
        }
      }

      @include media-breakpoint-down(sm) {
        margin-bottom: 0;
        [class*=col-] {
          margin-bottom: map_get($spacers, 3);
        }
      }

    }


  }
}
