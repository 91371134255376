@use '@angular/material' as mat;
@import '@angular/material/theming';

@mixin med4all-medicines-action-row-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .medicines-action-row {
    display: flex;
    height: 10rem;
    padding: 0 1rem 1rem;
  }

  .medicines-info-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-right: 2rem;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      margin-right: 0;
    }
  }

  .medicines-info__column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 15%;

    .medicines-info__column-part {
      min-height: 2rem;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      @include media-breakpoint-down(md) {
        .item-text__label {
          margin-right: 0;
        }

        &:last-of-type {
          text-align: right;
        }
      }
    }

    @include media-breakpoint-between(lg, xl) {
      width: 12%;
    }

    @include media-breakpoint-down(md) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }

  .medicines-info__column-quantity {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
    border-radius: 4px;
    padding: 1rem;
    width: 28%;

    .mat-input-element:disabled {
      color: mat.get-color-from-palette($palette, text);
    }

    .medicines-info__column-part {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    @include media-breakpoint-between(lg, xl) {
      width: 35%;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }

  .medicines-info__column-quantity-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    width: 100%;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-direction: column;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .medicines-info__column-quantity-button {
    width: 47%;
    font-size: 14px;

    .mat-button-wrapper {
      display: flex;
      flex-direction: column;
      font-size: $font-size-md;
      line-height: 26px;
    }

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-bottom: 1rem !important;

      &:last-of-type {
        margin-bottom: 0 !important;
      }
    }
  }

  .medicines-info__column-button-row {
    .medicines-info__column-button {
      width: 100%;
    }

    .medicine-info_column-icon,
    .medicine-info_column-button {
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }


  .medicines-action-row {
    .mat-form-field-hint-wrapper {
      flex-direction: column;
      padding-top: 1rem;
    }
  }

  .line-through {
    text-decoration: line-through;
  }


}

.edit-supplier-icon {
  line-height: normal;
  width: auto;
  height: auto;
}

.edit-supplier-icon:hover {
  background-color: white !important;
  color: darkblue;
}

