@mixin med4all-proforma-buttons-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .proforma-buttons-container {
    font-size: $font-size-lg;

    button {
      margin: 0 $spacer $spacer 0;
      display: inline-block;
    }
  }
}
