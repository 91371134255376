@use '@angular/material' as mat;
@mixin thumb_container($palette) {
  .thumbs-container {
    display: flex;
    align-content: center;
    overflow-y: auto;
    margin-bottom: 24px;

    .thumb {
      width: 240px;
      margin-right: 10px;
      position: relative;

      &.active {
        border: 4px solid mat.get-color-from-palette($palette, text-link);
      }

      img {
        width: 100px;
        height: auto;
        display: block;
      }

      .ng-fa-icon {
        color: mat.get-color-from-palette($palette, positive-green);
        position: absolute;
        bottom: 4px;
        right: 10px;
      }
    }
  }
}
