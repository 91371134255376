@mixin med4all-orders-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .orders-page {
    @extend .container;

    .add-to-cart-btn {
      @extend .icon-btn;
      margin-right: map_get($spacers, 3);
      font-size: 18px;


      @include media-breakpoint-down(sm) {
        margin-bottom: map_get($spacers, 3);
        margin-right: 0;
        line-height: 26px;
        width: 100%;

        .mat-button-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: map_get($spacers, 3);
          white-space: normal;


        }
      }
    }

    .previous-orders-label {
      font-size: $font-size-lg;

      @include media-breakpoint-down(sm) {
        width: 100%;
        text-align: center;
        margin-top: map_get($spacers, 3);
        display: block;
      }
    }
  }
}
