@mixin med4all-cart-summary-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .cart-summary {
    align-items: flex-end;

    .total-value {
      order: 2;
      &__label {
        font-weight: 500;
      }
      &__value {
        display: flex;
        justify-content: flex-end;
      }
    }

    .credit-space {
      order: 1;
      &__label {
        font-weight: 500;
      }
      &__value {
        display: flex;
        justify-content: flex-end;
      }
    }

    &.reverse {
      align-items: flex-start;

      .total-value {
        order: 1 !important;
      }

      .credit-space {
        order: 2 !important;
      }
    }
  }
}
