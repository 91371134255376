@mixin med4all-reports-page-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .reports-page {
    @extend .container;

    .highcharts-pivot {
      display: none;
    }

    .top-medicines {
      height: 400px;
      display: block;
    }


  }
}
