@use '@angular/material' as mat;

@mixin paf-default-overrides($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .mat-drawer-container {
    color: mat.get-color-from-palette($palette, text);

    .mat-drawer {
      min-width: 270px;

    }

    .mat-sidenav-content {
      overflow: hidden;
    }
  }

  .mat-menu-panel {
    max-width: 350px !important;
    margin-top: 10px;
    border-radius: 0 !important;
  }

  select {
    border: 1px solid silver;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 25px;
    background: url(data:image/gif;base64,R0lGODlhCwALAJEAAAAAAP///xUVFf///yH5BAEAAAMALAAAAAALAAsAAAIPnI+py+0/hJzz0IruwjsVADs=);
    background-repeat: no-repeat;
    background-position: 97% 42%;
  }

  .mat-bottom-sheet-container {
    padding: 0 !important;
  }

  button {
    &.mat-primary:hover {
      background-color: mat.get-color-from-palette($primary, 900) !important;
      color: #fff;
    }

    &.mat-primary[disabled] {
      background-color: mat.get-color-from-palette($palette, primary) !important;
      opacity: 0.6;
      color: #fff !important;
    }
  }

  .mat-snack-bar-container {
    &.error {
      background-color: mat.get-color-from-palette($palette, negative-red);
    }

    &.warning {
      background-color: mat.get-color-from-palette($palette, warning-yellow);
    }

    &.success {
      background-color: mat.get-color-from-palette($palette, positive-green);
    }

    &.default {
      background-color: mat.get-color-from-palette($greys, polaire);
    }
  }

  .mat-h1 {
    font: 500 24px/32px Roboto, sans-serif !important;
  }

  .cdk-global-scrollblock {
    overflow-y: hidden;
  }

  mat-form-field {
    display: block !important;

    &.no-padding {
      .mat-form-field-wrapper {
        padding-bottom: 0;
      }

      .mat-form-field-underline {
        bottom: 0;
      }
    }
  }

  .mat-dialog-container {
    padding: map_get($spacers, 3) !important;
  }

  table.mat-table {
    width: 100% !important;

    tr.mat-row {
      width: 100% !important;

      &.row-hover {
        cursor: pointer;

        &:hover {
          background: mat.get-color-from-palette($greys, light);
        }
      }
    }
  }

  .mat-tab-body-content {
    padding-top: 24px;
    overflow: hidden auto !important;
  }

  .mat-chip {
    &.mat-standard-chip {
      background-color: #fff;
      border: 1px solid mat.get-color-from-palette($secondary, 500);
      color: mat.get-color-from-palette($secondary, 500);

      &:not(:last-child) {
        margin-right: 24px;
      }

      .ng-fa-icon {
        color: mat.get-color-from-palette($secondary, 500);
        opacity: 1;
        font-size: 18px;
      }
    }
  }

  .mat-toolbar-single-row {
    height: 64px !important;
  }

  .mat-slide-toggle-thumb {
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  }

  .mat-dialog-actions {
    margin-bottom: 0 !important;
  }

  .mat-tab-label {
    font-size: 14px !important;
    opacity: 1 !important;
    padding: 0 15px !important;
    min-width: unset !important;
  }

  .mat-table{
    .mat-sort-header-arrow {
      transform: none !important;
      opacity: 1 !important;
    }
    th[aria-sort='ascending'], th[aria-sort='descending'] {
      color: mat.get-color-from-palette($palette, primary);
      .mat-sort-header-arrow {
        color: mat.get-color-from-palette($palette, primary);
      }
    }
  }

  .tooltip-whitespace {
    white-space: pre-line;
  }
}
