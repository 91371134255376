@use '@angular/material' as mat;
@import './variables';

.profile-menu {
  min-width: 206px !important;
  .mat-menu-item {
    color: mat.get-color-from-palette($med4all-palette, text);
    font-size: $font-size-lg !important;
    &:not(:last-child) {
      border-bottom: 1px solid mat.get-color-from-palette($med4all-greys, perle);
    }
  }
}

.main-menu {
  padding-top: 32px;
  min-width: 215px !important;

  .mat-list-base {
    padding-top: 0;
  }

  .mat-list-item {
    padding: 10px 32px 10px 28px !important;
    text-transform: uppercase;
    font-weight: 500;
    font-size: $font-size-md;
    height: 40px !important;

    &.active {
      padding: 10px 32px 10px 24px !important;
      border-left: 4px solid mat.get-color-from-palette($med4all-secondary, 500);
      background: #f9f9f9;
      color: #025c7f;
      text-transform: uppercase;
    }
  }
}

.order-journey-action-menu,
.admin-orders-action-menu {
  .mat-menu-item {
    color: mat.get-color-from-palette($med4all-palette, text-link);
  }
}
