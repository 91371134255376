@use '@angular/material' as mat;

@mixin med4all-base-monitor-overview-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .order-journeys-page {
    @extend .container;

    @include mat-table-columns((
      supplier: (width: 16rem, ellipsis: 'true'),
      provider: (width: 16rem, ellipsis: 'true'),
    ));

    .mat-column-actions button fa-icon {
      color: rgba(0, 0, 0, .54);
    }

    .comment-container {
      background: mat.get-color-from-palette($palette, primary);
      color: #fff;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mat-table {
      margin-bottom: 2px;
    }

    .mat-cell {
      padding: 5px 5px 0px 0;
    }

    .status {
      &.OKAY {
        color: mat.get-color-from-palette($palette, text);
      }

      &.RISK {
        font-weight: 500;
        color: mat.get-color-from-palette($palette, warning-yellow);
      }

      &.OVERDUE {
        font-weight: 500;
        color: mat.get-color-from-palette($palette, negative-red);
      }
    }

    .mat-toolbar {
      background: #fff;
    }
  }
}
