@mixin med4all-header-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);

  .app-header {
    background: #fff;

    .menu-btn {
      margin-right: map_get($spacers, 3);
    }

    .logo {
      display: flex;
      align-items: center;
      height: 100%;

      img {
        height: 47px;

        @include media-breakpoint-down(sm) {
          height: 35px;
        }
      }
    }
  }
}
