@mixin med4all-organizations-selector-component-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .organizations-selector-dialog-container {
    padding: map_get($spacers, 3);
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .intro {
      margin-bottom: 30px;
    }

    .organizations-container {
      font-size: 15px;
      margin-bottom: 20px;
      flex: 1;
      overflow-y: auto;
    }

    .button-bar {
      height: 50px;
      display: block;
      text-align: right;

      .button.small {
        width: 100px;
        display: inline-block;
      }
    }
  }
}
