@mixin med4all-change-quantity-dialog-theme($theme, $palette) {
  $primary: map_get($theme, primary);
  $secondary: map_get($theme, accent);
  $greys: map_get($theme, greys);

  .change-quantity-dialog__content-part {
    width: 100%;
    margin-bottom: 2rem;
  }
}
